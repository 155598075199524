import { isString } from 'lodash';

export function formattedPrice(
  price: number | string,
  locale = 'de-DE',
  customOptions?: Intl.NumberFormatOptions,
): string {
  const defaultOptions: Intl.NumberFormatOptions = {
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const options = { ...defaultOptions, ...customOptions };

  if (isString(price)) {
    // remove all characters except digits, comma, and period
    price = price.replace(/[^0-9,.-]/g, '');

    // handle decimal separators: German locale uses comma for decimal
    if (locale === 'de-DE') {
      // replace comma with period for proper number conversion
      price = price.replace(',', '.');
    }

    // if there are multiple decimal points or no digits, return the cleaned string
    if ((price.match(/\./g) || []).length > 1 || !/\d/.test(price)) {
      return price;
    }

    // convert the string to a number
    price = parseFloat(price);
  }

  if (typeof price !== 'number' || isNaN(price)) {
    return '';
  }

  return new Intl.NumberFormat(locale, options).format(price);
}
